import PropTypes from 'prop-types';
import React from 'react';
import CopyUrlButton from './CopyUrlButton';
import { baseUrl } from '../../../config';

export default function CalendarLink({ link, calendarId, remove }) {
  const url = `${baseUrl}calendar/${calendarId}/join/${link.token}`; // FIXME
  return (
    <li className="list-group-item bg-transparent d-flex justify-content-between">
      <CopyUrlButton url={url} />

      <div className="row mx-0">
        <div className="col-auto px-1">
          <select className="form-select" disabled defaultValue={link.role}>
            <option value={link.role}>{link.role}</option>
          </select>
        </div>

        <div className="col-auto px-1">
          <div className="btn-group" role="group" aria-label="Basic example">
            <button type="button" className="btn btn-danger" onClick={() => remove(link)}>
              Delete
            </button>
          </div>
        </div>
      </div>
    </li>
  );
}

CalendarLink.propTypes = {
  calendarId: PropTypes.string.isRequired,
  link: PropTypes.shape(
    {
      token: PropTypes.string,
      role: PropTypes.string,
    },
  ).isRequired,
  remove: PropTypes.func.isRequired,
};

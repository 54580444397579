import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

const dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export default function CalendarHeader({ name }) {
  return (
    <thead>
      <Helmet>
        <title>{name}</title>
      </Helmet>
      <tr>
        <th colSpan="7" className="month">{name}</th>
      </tr>
      <tr>
        {dayNames.map((value) => <th key={value}>{value}</th>)}
      </tr>
    </thead>
  );
}

CalendarHeader.propTypes = {
  name: PropTypes.string.isRequired,
};

import React, { useRef, useState } from 'react';
import { Button, Overlay, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';

export default function CopyUrlButton({ url }) {
  const [showTooltip, setShowTooltip] = useState(false);
  const target = useRef(null);

  return (
    <>
      <Button
        variant="outline-primary"
        ref={target}
        onClick={() => {
          navigator.clipboard.writeText(url)
            .then(() => {
              setShowTooltip(!showTooltip);
            });
        }}
      >
        <span className="text-break">
          {url}
        </span>
      </Button>
      <Overlay target={target.current} show={showTooltip} placement="right">
        {(props) => (
          // FIXME
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Tooltip id="url-copied-tooltip" {...props}>
            Copied to clipboard!
          </Tooltip>
        )}
      </Overlay>
    </>
  );
}

CopyUrlButton.propTypes = {
  url: PropTypes.string.isRequired,
};

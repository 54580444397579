import PropTypes from 'prop-types';
import React from 'react';

import { useNavigate } from 'react-router-dom';
import TaskList from './TaskList';
import DayShape from '../../propTypes/DayShape';

function CalendarDay({
  calendarId,
  year,
  month,
  day,
}) {
  const navigate = useNavigate();

  if (day.number === -1) {
    return <td className="day bg-light" />;
  }

  const goToDayPage = () => {
    navigate(`/calendar/${calendarId}/${year}/${month}/${day.number}`);
  };

  const date = new Date();

  let className = 'day clickable';
  if (year === date.getFullYear()
    && month === date.getMonth() + 1
    && day.number === date.getDate()) {
    className += ' today';
  }

  return (
    <td className={className} onClick={goToDayPage} role="presentation">
      <span className="date">{day.number}</span>

      <TaskList tasks={day.tasks} />
    </td>
  );
}

export default CalendarDay;

CalendarDay.propTypes = {
  calendarId: PropTypes.number.isRequired,
  day: DayShape.isRequired,
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
};

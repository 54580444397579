import React from 'react';
import PropTypes from 'prop-types';
import Calendar from './Calendar';

export default function CalendarFish({ calendarId, month, year }) {
  const emptyDay = { number: -1, tasks: [] };

  const weeks = Array(5)
    .fill({})
    .map((_v, i) => ({
      days: Array(7).fill(emptyDay),
      weekNum: i,
    }));

  return <Calendar calendar={weeks} calendarId={calendarId} month={month} year={year} isOk />;
}

CalendarFish.propTypes = {
  calendarId: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
};

import { Modal } from 'react-bootstrap';
import React, { useContext, useState } from 'react';
import * as PropTypes from 'prop-types';
import FormRow from './FormRow';

import api, { config } from '../api';
import UserContext from '../UserContext';

export default function NewCalendarModal({ opened, close, reload }) {
  const { token } = useContext(UserContext);

  const [name, setName] = useState();

  const [error, setError] = useState(null);

  const createCalendar = (calendar) => {
    api.post(
      '/calendar',
      calendar,
      config(token),
    ).then((res) => {
      if (res.status === 200) {
        setError('');
        reload();
        close();
      } else {
        setError(`Error: ${res.data}`);
      }
    }).catch(({ response }) => {
      if (response.data !== undefined) {
        setError(`Error: ${response.data}`);
      } else {
        setError('Unknown error.');
      }
    });
  };

  return (
    <Modal show={opened} onHide={() => close()}>
      <Modal.Header closeButton>
        <Modal.Title>Create calendar</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          id="createCalendarForm"
          onSubmit={(e) => {
            e.preventDefault();
            createCalendar({
              name,
            });
          }}
        >
          {error
            && (
              <div className="alert alert-danger login-error">
                {error}
              </div>
            )}

          <FormRow
            label="Calendar name:"
            inputId="name"
            required
            name="calendar name"
            type="text"
            maxLength="100"
            onChange={(e) => setName(e.target.value)}
          />

          <div className="d-flex justify-content-center">
            <input
              type="submit"
              className="btn btn-primary"
              value="Submit"
            />
          </div>

        </form>
      </Modal.Body>
    </Modal>
  );
}

NewCalendarModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  reload: PropTypes.func.isRequired,
};

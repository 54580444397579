import PropTypes from 'prop-types';
import DayShape from './DayShape';

const WeekShape = PropTypes.exact({
  weekNum: PropTypes.number,
  days: PropTypes.arrayOf(DayShape),
  lastDayNum: PropTypes.number,
});

export default WeekShape;

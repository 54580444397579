import React from 'react';
import useCastedParams, { asInt } from '../hooks/useCastedParams';
import LoadedDecorator from '../components/LoadedDecorator';

export default function TaskPage() {
  const {
    taskId,
  } = useCastedParams(
    {
      taskId: asInt,
    },
  );

  const error = '';
  const isLoaded = true;

  return (
    <div className="container">
      <div className="card">
        <LoadedDecorator showContent={!error && isLoaded} error={error}>
          <ul className="list-group list-group-flush">
            {/* {this.state.isLoaded && !this.state.error && */}
            {/* <DayEventsList events={day.events}/> */}
            {/* } */}

            {taskId}
            {/* <DayTask */}
            {/*  task={task} */}
            {/*  key={task.id} */}
            {/*  updateTask={updateTask} */}
            {/*  calendarId={calendarId} */}
            {/*  deleteTask={deleteTask} */}
            {/* /> */}

            {/* {this.state.isLoaded && !this.state.error && */}
            {/* <DayActivitiesList activities={day.activities}/> */}
            {/* } */}
          </ul>
        </LoadedDecorator>
      </div>
    </div>
  );
}

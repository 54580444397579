import { Link } from 'react-router-dom';
import React from 'react';
import TaskShape from '../../propTypes/TaskShape';

export default function Task({ task }) {
  let { name } = task;

  if (name.length > 12) {
    name = `${name.slice(0, 10)}...`;
  }

  return (
    <li className="badge rounded-pill task-name">
      <Link to="/">
        {/* FIXME add link to task */}
        {name}
      </Link>
    </li>
  );
}

Task.propTypes = {
  task: TaskShape.isRequired,
};

const React = require('react');

const UserContext = React.createContext({
  token: null,
  logout: () => null,
  role: 'NONE',
  userId: null,
});

export default UserContext;

import React from 'react';

import PropTypes from 'prop-types';
import DayTask from './DayTask';
import TaskShape from '../../propTypes/TaskShape';

export default function DayTasksList({
  calendarId, tasks, updateTask, deleteTask,
}) {
  return (
    <li className="list-group-item">
      <h4 className="day-section-title mt-2 mb-3">Tasks</h4>

      {tasks.map((task) => (
        <DayTask
          task={task}
          key={task.id}
          updateTask={updateTask}
          calendarId={calendarId}
          deleteTask={deleteTask}
        />
      ))}
    </li>
  );
}

DayTasksList.propTypes = {
  calendarId: PropTypes.number.isRequired,
  deleteTask: PropTypes.func.isRequired,
  tasks: PropTypes.arrayOf(TaskShape).isRequired,
  updateTask: PropTypes.func.isRequired,
};

import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';
import NavItem from './NavItem';

function DropdownLink({
  to,
  text,
}) {
  return (
    <li>
      <Link className="dropdown-item" to={to}>{text}</Link>
    </li>
  );
}

export default function Navbar({ isTokenValid }) {
  return (
    <nav className="navbar navbar-dark navbar-expand-lg navbar-custom bg-main mb-1">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand">TimeToGo</Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto">
            {isTokenValid() && (
              <NavItem text="Calendar" to="/calendar" />
            )}

            <NavItem text="About us" to="/about-us" />

          </ul>
          <ul className="navbar-nav ms-auto">
            <li className="nav-item dropdown">
              <a
                href="#account"
                type="button"
                className="nav-link dropdown-toggle"
                id="navbarDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                My account
              </a>

              <ul className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                {isTokenValid() && (
                  <div>
                    <DropdownLink text="Profile" to="/profile" />
                    <DropdownLink text="Logout" to="/logout" />
                  </div>
                )}

                {!isTokenValid() && (
                  <div>
                    <DropdownLink text="Sign In" to="/sign-in" />
                    <DropdownLink text="Sign Up" to="/sign-up" />
                  </div>
                )}
              </ul>

            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

DropdownLink.propTypes = {
  text: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};

Navbar.propTypes = {
  isTokenValid: PropTypes.func.isRequired,
};

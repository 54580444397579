import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UserShape from '../../../propTypes/UserShape';
import roles from '../../../roles';

export default function EditedCalendarMember({ member, update, deleteMember }) {
  const [role, setRole] = useState(member.role);

  return (
    <li className="list-group-item bg-transparent d-flex justify-content-between">
      <span className="align-self-center">
        {member.user.firstName}
        &nbsp;
        {member.user.lastName}
      </span>

      <div className="row mx-0">
        <div className="col-auto px-1">
          <select
            className="form-select"
            disabled={member.role === 'OWNER'}
            onChange={(e) => setRole(e.target.value)}
            value={role}
          >
            {roles.map(
              ([value, text]) => (<option value={value} key={value} disabled={value === 'OWNER'}>{text}</option>),
            )}
          </select>
        </div>

        <div className="col-auto px-1">
          <div className="btn-group" role="group">
            <button
              type="button"
              className="btn btn-success"
              disabled={member.role === 'OWNER'}
              onClick={() => {
                update({ user: member.user, role });
              }}
            >
              Save
            </button>

            <button type="button" className="btn btn-danger" disabled={member.role === 'OWNER'} onClick={() => deleteMember(member.user.id)}>
              Delete
            </button>
          </div>
        </div>

      </div>

    </li>
  );
}

EditedCalendarMember.propTypes = {
  member: PropTypes.shape({
    user: UserShape,
    role: PropTypes.string,
  }).isRequired,
  update: PropTypes.func.isRequired,
  deleteMember: PropTypes.func.isRequired,
};

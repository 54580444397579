import { Modal } from 'react-bootstrap';
import React, { useContext, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import FormRow from './FormRow';
import api, { config } from '../api';
import UserContext from '../UserContext';
import useInput from '../hooks/useInput';
import { debug } from '../config';

export default function NewTaskModal({
  openedModal,
  openModal,
  calendarId,
  updateCallback,
  initDate,
}) {
  const handleClose = () => {
    openModal(null);
  };

  const { token } = useContext(UserContext);

  const name = useInput('');
  const date = useInput(initDate);
  const time = useInput('');
  const frequency = useInput('NEVER');
  const repeatableUntil = useInput('');
  const duration = useInput('');
  const priority = useInput('2');

  const [error, setError] = useState();

  const { logout } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  const createTask = (task) => {
    api.post(`calendar/${calendarId}`, task, config(token))
      .then(() => {
        updateCallback();
        openModal(null);
      })
      .catch((reason) => {
        if (reason.response.status === 401) {
          logout(navigate, location); // FIXME check
        }

        if (reason.response.data) {
          setError(reason.response.data.message);
        } else {
          debug('Error:', reason);
        }
      });
  };

  const parseDuration = (durationStr) => {
    const [hour, min] = durationStr.split(':');
    return 60 * parseInt(hour, 10) + parseInt(min, 10);
  };

  return (
    <Modal show={openedModal === 'task'} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create task</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          id="createTaskForm"
          onSubmit={(e) => {
            e.preventDefault();
            const dateTimeObj = new Date(Date.parse(`${date.value} ${time.value}`));

            createTask({
              name: name.value,
              dateTime: dateTimeObj,
              frequency: frequency.value,
              repeatableUntil: new Date(Date.parse(`${repeatableUntil.value}`)),
              duration: parseDuration(duration.value),
              priority: priority.value,
            });
          }}
        >
          {error
            && (
              <div className="alert alert-danger login-error">
                {error}
              </div>
            )}

          <FormRow
            label="Task name:"
            inputId="name"
            required
            name="task name"
            type="text"
            maxLength="100"
            {...name}
          />

          <FormRow
            label="Date:"
            inputId="date"
            required
            name="date"
            type="date"
            {...date}
          />

          <FormRow
            label="Time:"
            inputId="time"
            required
            name="time"
            type="time"
            {...time}
          />

          <FormRow
            label="Priority:"
            inputId="priority"
            required
            name="priority"
          >
            <select
              name="priority"
              id="priority"
              className="form-control"
              {...priority}
            >
              <option value="0">Low</option>
              <option value="1">Below Normal</option>
              <option value="2">Normal</option>
              <option value="3">Above Normal</option>
              <option value="4">High</option>
            </select>
          </FormRow>

          <FormRow
            label="Repeat:"
            inputId="repeat"
            required
            name="repeat"
          >
            <select
              name="repeat"
              id="repeat"
              className="form-control"
              {...frequency}
            >
              {/* FIXME extract */}
              <option value="NEVER">Never</option>
              <option value="EVERY_DAY">Every day</option>
              <option value="EVERY_WEEK">Every week</option>
              <option value="EVERY_2_WEEK">Every 2 week</option>
              <option value="EVERY_MONTH">Every month</option>
              <option value="EVERY_YEAR">Every year</option>
            </select>
          </FormRow>

          {frequency.value !== 'NEVER' && (
            <FormRow
              label="Until:"
              inputId="until"
              required
              name="until"
              type="date"
              {...repeatableUntil}
            />
          )}

          <FormRow
            label="Execution time:"
            inputId="minutesForExecutions"
            required
            name="execution time"
            type="time"
            {...duration}
          />

          {/* <FormRow */}
          {/*  label="Split into tasks:" */}
          {/*  inputId="split_into_tasks" */}
          {/*  required */}
          {/*  name="execution time" */}
          {/*  type="number" */}
          {/*  value="1" */}
          {/*  min="1" */}
          {/*  max="7" */}
          {/*  // onChange={(e) => setUsername(e.target.value)} FIXME */}
          {/* /> */}

          <div className="d-flex justify-content-center">
            <input
              type="submit"
              className="btn btn-primary"
              value="Submit"
            />
          </div>

        </form>
      </Modal.Body>
    </Modal>
  );
}

NewTaskModal.propTypes = {
  openedModal: PropTypes.string,
  openModal: PropTypes.func.isRequired,
  calendarId: PropTypes.number.isRequired,
  updateCallback: PropTypes.func,
  initDate: PropTypes.string,
};

NewTaskModal.defaultProps = {
  openedModal: null,
  updateCallback: () => {
  },
  initDate: '',
};

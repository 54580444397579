import React from 'react';
import PropTypes from 'prop-types';
import CalendarDay from './CalendarDay';
import WeekShape from '../../propTypes/WeekShape';

export default function CalendarWeek({
  calendarId,
  year,
  month,
  week,
}) {
  return (
    <tr>
      {week.days.map(
        (day, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <CalendarDay day={day} key={i} calendarId={calendarId} year={year} month={month} />
        ),
      )}
    </tr>
  );
}

CalendarWeek.propTypes = {
  week: WeekShape.isRequired,

  calendarId: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
};

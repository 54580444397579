import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable react/jsx-props-no-spreading */
export default function FormRow({
  label,
  inputId,
  children,
  ...rest
}) {
  const isRequired = Object.prototype.hasOwnProperty.call(rest, 'required');

  const isGroup = Array.isArray(children);

  const className = isGroup ? 'input-group mb-3' : 'mb-3';
  const labelClass = isGroup ? 'input-group-text' : 'form-label';

  return (
    <div className={className}>
      <label htmlFor={inputId} className={labelClass}>
        {label}
        {
          isRequired && <span className="error-text"> *</span>
        }
      </label>
      {!children
        ? <input className="form-control" id={inputId} {...rest} />
        : children}
    </div>
  );
}

FormRow.defaultProps = {
  children: null,
};

FormRow.propTypes = {
  label: PropTypes.string.isRequired,
  inputId: PropTypes.string.isRequired,
  children: PropTypes.node,
};

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TaskShape from '../../propTypes/TaskShape';
import FrequencyText from '../../FrequencyText';

export default function DayTask({
  calendarId,
  task,
  updateTask,
  deleteTask,
}) {
  const date = new Date(task.dateTime);
  const dateTime = date.toLocaleString('en', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });

  let className = 'card card-task mb-3';

  if (task.done) {
    className += ' border-success';
  }

  function setTaskDone(status) {
    const newTask = task;
    newTask.done = status;
    updateTask(newTask);
  }

  const isRepeatable = task.frequency !== 'NEVER';

  return (
    <div className={className}>
      <div className="card-body">
        <div className="card-header bg-transparent border-bottom-0 mb-0 pb-0 text-center">
          <div className="row">
            <div className="col">
              <h5 className="card-title task-title">
                <Link to={`/calendar/${calendarId}/tasks/${task.id}`}>{task.name}</Link>
              </h5>
              <h6 className="card-subtitle mb-2 text-muted">{dateTime}</h6>
              {isRepeatable && (
                <h6 className="card-subtitle mb-2 text-muted">
                  Repeat
                  {' '}
                  {FrequencyText(task.frequency, date)}
                </h6>
              )}
            </div>
            <div className="col-1">
              {/* FIXME check perm */}
              <button
                type="button"
                className="btn close text-danger"
                onClick={() => deleteTask(task.id)}
              >
                <i className="bi bi-trash-fill task-delete-btn" aria-hidden="true" />
              </button>
            </div>
          </div>

        </div>

        <div className="card-text">
          Duration:
          {' '}
          <b>{task.duration}</b>
          {' '}
          minutes
        </div>

      </div>
      <div className="card-footer">
        <div className="row">
          <div className="col-11 text-center">
            {/* FIXME check perm */}

            {!task.done
              && (
                <button
                  type="button"
                  className="btn btn-mark-style btn-success"
                  onClick={() => setTaskDone(true)}
                >
                  Mark
                  done
                </button>
              )}

            {task.done
            && (
              <button
                type="button"
                className="btn btn-mark-style btn-secondary"
                onClick={() => setTaskDone(false)}
              >
                Mark
                undone
              </button>
            )}
          </div>
        </div>

      </div>
    </div>
  );
}

DayTask.propTypes = {
  task: TaskShape.isRequired,
  calendarId: PropTypes.number.isRequired,
  updateTask: PropTypes.func.isRequired,
  deleteTask: PropTypes.func.isRequired,
};

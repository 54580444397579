import React from 'react';

import PropTypes from 'prop-types';
import CalendarHeader from './CalendarHeader';
import CalendarWeek from './CalendarWeek';
import WeekShape from '../../propTypes/WeekShape';

export default function Calendar({
  calendar,
  calendarId,
  year,
  month,
  isOk,
}) {
  const date = new Date(year, month - 1, 1);
  const monthName = date.toLocaleString('en', {
    month: 'short',
    year: 'numeric',
  });

  return (
    <table className="table calendar ps-5 pe-5">
      <CalendarHeader name={monthName} />
      <tbody>
        {isOk && (
          calendar.map((week) => (
            <CalendarWeek
              week={week}
              key={week.weekNum}
              calendarId={calendarId}
              year={year}
              month={month}
            />
          ))
        )}
      </tbody>
    </table>
  );
}

Calendar.propTypes = {
  calendar: PropTypes.arrayOf(
    WeekShape,
  ),
  calendarId: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
  isOk: PropTypes.bool.isRequired,
};

Calendar.defaultProps = {
  calendar: null,
};

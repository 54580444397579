import React from 'react';
import PropTypes from 'prop-types';
import api, { config } from '../api';
import UserContext from '../UserContext';
import NewLinkModal from './NewLinkModal';
import CalendarLink from './calendar/edit/CalendarLink';

export default class CalendarLinks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isLoaded: false,
      links: [],
      openModal: false,
    };
  }

  componentDidMount() {
    this.loadLinks();
  }

  loadLinks() {
    const {
      token,
      logout,
    } = this.context;

    const {
      calendarId,
      navigate,
    } = this.props;

    api.get(`/join/${calendarId}/`, config(token))
      .then(
        ({ data }) => {
          this.setState({
            isLoaded: true,
            links: data,
          });
        },
      )
      .catch((error) => {
        if (error.response !== undefined && error.response.status === 401) {
          logout();

          navigate('/sign-in');
        }

        this.setState(
          {
            isLoaded: true,
            error: error.message,
          },
        );
      });
  }

  render() {
    const {
      links, error, isLoaded, openModal,
    } = this.state;
    const { calendarId } = this.props;
    const { token } = this.context;

    const removeLink = (link) => {
      api.delete(`/join/${calendarId}/${link.token}`, config(token))
        .then(() => {
          this.setState({ links: links.filter((iLink) => iLink.token !== link.token) });
        });
    };

    const createLink = (link) => {
      // TODO extract url
      api.post(`/join/${calendarId}`, link, config(token))
        .then((res) => {
          links.push(res.data);
          this.setState({ links });
        });
    };

    return (
      <>
        <div className="list-group">
          <div className="d-flex justify-content-between">
            <h5 className="mt-3">Links:</h5>

            <button type="button" className="btn" onClick={() => this.setState({ openModal: true })}>
              <i className="bi bi-plus-square-fill" />
            </button>
          </div>
          <ul className="list-group">
            {error
              && (
                <div className="alert alert-danger login-error">
                  {error}
                </div>
              )}

            {isLoaded && !error && (
              links.map(
                (link) => (
                  <CalendarLink
                    calendarId={calendarId}
                    link={link}
                    remove={removeLink}
                    key={link.token}
                  />
                ),
              )
            )}

          </ul>
        </div>
        <NewLinkModal
          close={() => this.setState({ openModal: false })}
          opened={openModal}
          createLink={createLink}
        />
      </>
    );
  }
}

CalendarLinks.propTypes = {
  calendarId: PropTypes.string.isRequired,
  navigate: PropTypes.func.isRequired,
};

CalendarLinks.contextType = UserContext;

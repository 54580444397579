import PropTypes from 'prop-types';
import React, { useState } from 'react';
import './LoginPage.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import FormRow from '../components/FormRow';

import API from '../api';
import useInput from '../hooks/useInput';
import { debug } from '../config';

function LoginPage({
  setToken,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const username = useInput('');
  const password = useInput('');

  const [error, setError] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    API.post('login', {
    }, {
      auth: {
        username: username.value,
        password: password.value,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setToken(res.data);
          setError(false);

          const { from } = location.state || { from: '/' };
          debug('Router state:', location.state);
          debug('Redirect to:', from);

          navigate(from);
        } else {
          setError(res?.data?.message || 'Wrong credentials');
        }
      })
      .catch((reason) => {
        setError(reason.response?.data?.message || 'Wrong credentials');
      });
  };

  return (
    <div className="container">
      <Helmet>
        <title>Sign In</title>
      </Helmet>

      <div className="card login-card mt-3 text-white">
        <div className="card-header login-card-header">
          <h5 className="modal-title text-center" id="modalLabel">SIGN IN</h5>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            {error
              && (
                <div className="alert alert-danger login-error">
                  {error}
                  {'! '}
                  Try again.
                </div>
              )}

            <FormRow
              label="Username:"
              inputId="username"
              required
              autoComplete="username"
              name="username"
              type="text"
              {...username}
            />

            <FormRow
              label="Password:"
              inputId="password"
              required
              autoComplete="current-password"
              name="password"
              type="password"
              {...password}
            />

            <div className="mb-3 mt-4 d-flex justify-content-center">
              <input type="submit" className="btn btn-login" value="Sign In" />
            </div>
          </form>

          <p className="mt-2 text-center">
            Don&apos;t have an account yet?
            {' '}
            <Link className="login-link" to="/sign-up">Sign Up</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;

LoginPage.propTypes = {
  setToken: PropTypes.func.isRequired,
};

import React, { useContext } from 'react';

import { Navigate } from 'react-router-dom';

import UserContext from '../UserContext';

export default function LogoutPage() {
  const context = useContext(UserContext);

  context.logout();

  return <Navigate to="/" />;
}

import React from 'react';
import PropTypes from 'prop-types';

export default function Note({ note }) {
  return (
    <div className="card border-primary mb-3 note">
      <div className="card-header bg-transparent">
        <div className="d-flex align-items-center">
          <h5 className="card-title flex-grow-1 mb-0">{note.title}</h5>
          <div className="float-end">
            <button type="button" className="btn m-0 p-1">
              <i className="bi bi-trash" />
            </button>
          </div>
        </div>
      </div>

      <div className="card-body">
        <div className="card-text">
          {note.text}
        </div>
      </div>
    </div>
  );
}

Note.propTypes = {
  note: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
};

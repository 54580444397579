import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import NewTaskModal from './NewTaskModal';

function neighboursDays(day, month, year) {
  const date = new Date(Date.parse(`${year}-${month}-${day}`));

  const previous = new Date();
  previous.setDate(date.getDate() - 1);

  const next = new Date();
  next.setDate(date.getDate() + 1);

  return [previous, next];
}

function pad(number) {
  if (number < 10) {
    return `0${number}`;
  }
  return number;
}

export default function DayHeader({
  day, month, year, calendarId, updateCallback,
}) {
  const date = `${year}-${pad(month)}-${pad(day)}`;

  const [previous, next] = neighboursDays(day, month, year);

  const [openedModal, setOpenedModal] = useState('');

  const previousUrl = `/calendar/${calendarId}/${previous.getFullYear()}/${previous.getMonth() + 1}/${previous.getDate()}/`;
  const nextUrl = `/calendar/${calendarId}/${next.getFullYear()}/${next.getMonth() + 1}/${next.getDate()}/`;
  const monthUrl = `/calendar/${calendarId}/${year}/${month}/`;
  // FIXME add task creating modal
  const dateStr = `${day}.${month}.${year}`;
  return (
    <>
      {openedModal && (
        <NewTaskModal
          openedModal={openedModal}
          openModal={setOpenedModal}
          calendarId={calendarId}
          initDate={date}
          updateCallback={updateCallback}
        />
      )}

      <Helmet>
        <title>
          {dateStr}
        </title>
      </Helmet>

      <div className="row">
        <div className="col col-md-1">
          <Link className="btn calendar-btn" to={monthUrl}>
            <i className="bi bi-arrow-90deg-up" />
          </Link>
        </div>

        <div className="col col-md-3">
          <Link className="btn calendar-btn" to={previousUrl}>
            {'<<'}
          </Link>
        </div>
        <div className="col col-md">
          <h3 className="card-title day-title text-center">
            Date:&nbsp;
            {dateStr}
          </h3>
        </div>
        <div className="col col-md-3">
          <Link className="btn calendar-btn" to={nextUrl}>
            {'>>'}
          </Link>
        </div>
        <div className="col col-md-1">
          <button className="btn calendar-btn" onClick={() => setOpenedModal('task')} type="button">
            {/* FIXME add events */}
            <i className="bi bi-plus" />
          </button>
        </div>
      </div>
    </>
  );
}

DayHeader.propTypes = {
  calendarId: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
  day: PropTypes.number.isRequired,
  updateCallback: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import Task from './Task';
import TaskShape from '../../propTypes/TaskShape';

export default function TaskList({ tasks }) {
  return (
    <ul className="task-list">
      {tasks.map((task) => <Task key={task.id} task={task} />)}
    </ul>
  );
}

TaskList.propTypes = {
  tasks: PropTypes.arrayOf(TaskShape).isRequired,
};

import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import api, { config } from '../api';
import UserContext from '../UserContext';
import EditedCalendarMember from '../components/calendar/edit/EditedCalendarMember';
import CalendarLinks from '../components/CalendarLinks';
import useRequest from '../hooks/useRequest';
import FormRow from '../components/FormRow';

export default function EditCalendarPage() {
  const { token } = useContext(UserContext);

  const {
    calendarId,
  } = useParams();

  const navigate = useNavigate();

  const [calendar, isLoaded, error, setCalendar] = useRequest(
    () => api.get(`/calendar/${calendarId}/`, config(token)),
    [calendarId],
  );

  const setName = (newName) => {
    setCalendar({ ...calendar, name: newName });
  };

  const renameCalendar = () => {
    api.put(`calendar/${calendarId}/name`, { name: calendar.name }, config(token)).then();
  };

  const updateMember = (member) => {
    api.put(`calendar/${calendarId}/calendarMembers/${member.user.id}`, { role: member.role }, config(token))
      .then(() => {
        setCalendar({
          ...calendar,
          calendarMembers: calendar.calendarMembers.map(
            (m) => {
              if (m.user.id !== member.user.id) {
                return m;
              }

              return member;
            },
          ),
        });
      });
  };

  const deleteMember = (userId) => {
    api.delete(`calendar/${calendarId}/calendarMembers/${userId}`, config(token))
      .then(() => {
        setCalendar({
          ...calendar,
          calendarMembers: calendar.calendarMembers.filter(
            (member) => member.user.id !== userId,
          ),
        });
      });
  };

  return (
    <div className="container">
      <Helmet>
        <title>
          Edit calendar
        </title>
      </Helmet>
      <div className="card mt-3">
        <div className="card-body">
          {error
            && (
              <div className="alert alert-danger login-error">
                {error}
              </div>
            )}
          {isLoaded && !error && (
            <div>
              <div>
                <FormRow
                  inputId="calendar-name"
                  label="Calendar name:"
                >
                  <input
                    className="form-control"
                    required
                    id="calendar-name"
                    name="name"
                    type="text"
                    onChange={(e) => setName(e.target.value)}
                    value={calendar.name}
                  />
                  <button className="btn btn-success" type="button" onClick={renameCalendar}>Rename</button>
                </FormRow>
              </div>

              <div className="d-grid gap-3">
                <div className="list-group">
                  <div className="d-flex justify-content-between">
                    <h5 className="mt-3">Members:</h5>
                  </div>
                  <ul className="list-group">
                    {calendar.calendarMembers.map((member) => (
                      <EditedCalendarMember
                        member={member}
                        update={updateMember}
                        deleteMember={deleteMember}
                        key={member.user.id}
                      />
                    ))}
                  </ul>
                </div>

                <CalendarLinks calendarId={calendarId} navigate={navigate} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

import PropTypes from 'prop-types';
import React from 'react';

import './Navbar.scss';
import { NavLink } from 'react-router-dom';

export default function NavItem({
  text,
  to,
}) {
  return (
    <li className="nav-item">
      <NavLink to={to} className={({ isActive }) => `nav-link${isActive ? ' active' : ''}`}>
        {text}
      </NavLink>
    </li>
  );
}

NavItem.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function GoToDay({ calendarId }) {
  const [date, setDate] = useState();

  const formattedDate = date?.replaceAll('-', '/');
  let gotoLink = '#';
  if (date) {
    gotoLink = `/calendar/${calendarId}/${formattedDate}`;
  }

  return (
    <div className="row">
      <div className="col-6">
        <input
          className="date-input w-100 h-100"
          type="date"
          onChange={(e) => setDate(e.target.value)}
        />
      </div>
      <div className="col-6">
        <Link className="btn btn-goto w-100 h-100" to={gotoLink}>
          Go to date
        </Link>
      </div>
    </div>
  );
}
GoToDay.propTypes = {
  calendarId: PropTypes.number.isRequired,
};

import React, { useContext, useState } from 'react';
import {
  Navigate, useLocation, useNavigate, useParams,
} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import api, { config } from '../api';
import UserContext from '../UserContext';

export default function JoinCalendarPage() {
  const { calendarId, token } = useParams();

  const {
    logout,
    token: jwtToken,
    isTokenValid,
  } = useContext(UserContext);

  const navigate = useNavigate();
  const location = useLocation();

  if (!isTokenValid()) {
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }

  const [error, setError] = useState(null);

  const join = () => {
    api.post(`/join/${calendarId}/join`, { token }, config(jwtToken))
      .then(() => {
        navigate(`/calendar/${calendarId}`);
      })
      .catch((reqError) => {
        if (reqError.response && reqError.response.status === 401) {
          logout(navigate, location);
        }

        setError(`${reqError.message}: ${reqError.response.data}`);
      });
  };

  return (
    <div className="container">
      <Helmet>
        <title>Join calendar</title>
      </Helmet>

      <div className="card login-card mt-3 text-white">
        <div className="card-header login-card-header">
          <h5 className="modal-title text-center" id="modalLabel">Join calendar</h5>
        </div>
        <div className="card-body">
          {error
            && (
              <div className="alert alert-danger login-error">
                {error}
              </div>
            )}

          <div className="mb-3 mt-4 d-flex justify-content-center">
            <button type="submit" className="btn btn-login" value="Join" onClick={join}>Join</button>
          </div>
        </div>
      </div>
    </div>
  );
}

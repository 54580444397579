import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import UserContext from '../UserContext';
import { debug } from '../config';

export default function useRequest(request, deps = []) {
  const [data, setData] = useState(null);

  const [isLoaded, setLoaded] = useState(false);

  const [error, setError] = useState('');

  const { logout } = useContext(UserContext);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setLoaded(false);
    request()
      .then((response) => {
        setData(response.data);
      })
      .catch((reason) => {
        if (reason.response.status === 401) {
          logout(navigate, location); // FIXME check
        }

        if (reason.response.data) {
          setError(reason.response.data.message);
        } else {
          setError('Unknown error.');
          debug('Error:', reason);
        }
      })
      .finally(() => {
        setLoaded(true);
      });
  }, deps);

  return [data, isLoaded, error, setData];
}

import './App.scss';

import React, { useMemo } from 'react';

import {
  BrowserRouter, Navigate, Route, Routes,
} from 'react-router-dom';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import Navbar from './components/navbar/Navbar';
import MainPage from './pages/MainPage';
import AboutUsPage from './pages/AboutUsPage';
import CalendarPage from './pages/CalendarPage';
import LoginPage from './pages/LoginPage';
import RegistrationPage from './pages/RegistrationPage';
import useToken from './hooks/useToken';

import UserContext from './UserContext';
import RedirectToCalendar from './components/RedirectToCalendar';
import LogoutPage from './pages/LogoutPage';
import DayPage from './pages/DayPage';
import CalendarsPage from './pages/CalendarsPage';
import EditCalendarPage from './pages/EditCalendarPage';
import JoinCalendarPage from './pages/JoinCalendarPage';
import TaskPage from './pages/TaskPage';

function App() {
  const {
    token,
    setToken,
    role,
    userId,
    logout,
    isTokenValid,
  } = useToken();

  const userContext = useMemo(() => ({
    token,
    role,
    userId,
    logout,
    isTokenValid,
  }));
  return (
    <HelmetProvider>
      <UserContext.Provider value={userContext}>
        <BrowserRouter>
          <Helmet>
            <title>JTimeToGo</title>
          </Helmet>
          <Navbar isTokenValid={isTokenValid} />
          <Routes>
            <Route path="/" element={<MainPage />} />

            <Route path="about-us" element={<AboutUsPage />} />

            <Route path="sign-in" element={<LoginPage setToken={setToken} />} />

            <Route path="sign-up" element={<RegistrationPage />} />
            {/* Test */}
            <Route path="calendar">
              <Route index element={<CalendarsPage />} />

              <Route path=":calendarId" element={<RedirectToCalendar />} />

              <Route path=":calendarId/edit" element={<EditCalendarPage />} />

              <Route path=":calendarId/join/:token" element={<JoinCalendarPage />} />

              <Route path=":calendarId/tasks/:taskId" element={<TaskPage />} />

              <Route path=":calendarId/:year/:month">
                <Route index element={<CalendarPage />} />

                <Route path=":day" element={<DayPage />} />
              </Route>
            </Route>

            <Route path="logout" element={<LogoutPage />} />

            <Route
              path="*"
              element={
                <Navigate to="/" />
              }
            />
          </Routes>

        </BrowserRouter>
      </UserContext.Provider>
    </HelmetProvider>
  );
}

export default App;

import PropTypes from 'prop-types';

const TaskShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  dateTime: PropTypes.string,
  frequency: PropTypes.string,
  repeatableUntil: PropTypes.string,
  duration: PropTypes.number,
  done: PropTypes.bool,
  priority: PropTypes.number,
  calendarId: PropTypes.number,
});

export default TaskShape;

import './CalendarSidebar.scss';

import React from 'react';
import Note from '../Note';

export default function CalendarSidebar() {
  // TODO add notes
  const notes = [
    {
      id: 1,
      title: 'Note title',
      text: 'Note text. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dignissimos dolorem similique totam! Dolores nostrum ratione recusandae rem ut.',
    },
    {
      id: 2,
      title: 'Note title 2',
      text: 'Note text. Lorem ipsum dolor sit amet, adipisicing elit. Dignissimos dolorem! Dolores nostrum recusandae rem ut.',
    },
  ];

  return (
    <div className="calendar-sidebar card mb-3">
      <div className="card-header notes-card-header">
        {/* TODO add notes modal */}
        <button
          type="button"
          className="btn-primary btn-block add-note-btn"
          data-toggle="modal"
          data-target="#addNote"
        >
          Add Note
        </button>
        {/* <div className="modal fade" id="addNote" tabIndex="-1" role="dialog"
         aria-labelledby="modalLabel" */}
        {/*     aria-hidden="true"> */}
        {/*    <div className="modal-dialog" role="document" style="border-color: #6c3a6f;"> */}
        {/*        <div className="modal-content"> */}
        {/*            <div className="modal-header"> */}
        {/*                <h5 className="modal-title" id="modalLabel">Add Note</h5> */}
        {/*                <button type="button" className="close" data-dismiss="modal"
        aria-label="Close"><span */}
        {/*                    aria-hidden="true">&times;</span></button> */}
        {/*            </div> */}
        {/*            <div className="modal-body"> */}
        {/*                <form id="createNoteForm" */}
        {/*   action="{% url 'task_calendar:add_note' %}?next={{ request.get_full_path }}" */}
        {/*                      method="post"> {% csrf_token %} */}
        {/*                    {% for field in form %} */}
        {/*                    <div className="fieldWrapper form-group" */}
        {/* aria-required="{% if field.field.required %} true {% else %} false {% endif %}"> */}
        {/*                        {{field.label_tag}}{% if field.field.required %} */}
        {/*                        <span className="required">*</span>{% endif %} */}
        {/*                        {{field}} */}
        {/*                        {% if field.help_text %} */}
        {/*                        <p className="help">{{field.help_text | safe}}</p> */}
        {/*                        {% endif %} */}
        {/*                    </div> */}
        {/*                    {% endfor %}<br> */}
        {/*                        <input type="submit" className="btn btn-primary" */}
        {/* style="margin-left: 35%; background-color: blue; color: white;" */}
        {/*                               value="Submit"> */}
        {/*                </form> */}
        {/*            </div> */}
        {/*        </div> */}
        {/*    </div> */}
        {/* </div> */}

      </div>
      <div className="card-body overflow-auto" id="sortable">
        {notes.map((note) => (<Note key={note.id} note={note} />))}
      </div>
    </div>
  );
}

import './DayPage.scss';

import React, { useContext, useState } from 'react';
import UserContext from '../UserContext';
import api, { config } from '../api';
import CalendarCard from '../components/CalendarCard';
import NewCalendarModal from '../components/NewCalendarModal';
import useRequest from '../hooks/useRequest';

export default function CalendarsPage() {
  const [showModal, setShowModal] = useState(false);

  const { token } = useContext(UserContext);
  const [ver, setVersion] = useState(0);

  const [calendars, isLoaded, error] = useRequest(
    () => api.get('/calendar/', config(token)),
    [ver],
  );

  return (
    <div className="container">
      <NewCalendarModal
        close={() => setShowModal(false)}
        opened={showModal}
        reload={() => setVersion(ver + 1)}
      />

      <div className="card">
        <div className="card-body row">
          <div className="col-3" />
          <div className="col">
            <h3 className="card-title day-title text-center">Calendars</h3>
          </div>
          <div className="col-3 d-flex flex-row-reverse">
            <button className="btn btn-dark" type="button" onClick={() => setShowModal(true)}>
              New
              calendar
            </button>
          </div>

        </div>

        {error
          && (
            <div className="alert alert-danger login-error">
              {error}
            </div>
          )}
        {isLoaded && !error && (

          <ul className="list-group list-group-flush">
            {calendars.map((calendar) => (
              <CalendarCard
                calendar={calendar}
                key={calendar.id}
              />
            ))}
          </ul>
        )}

      </div>
    </div>
  );
}

import React from 'react';
import { Navigate } from 'react-router-dom';

function RedirectToCalendar() {
  const currentDate = new Date();

  const defaultCalendar = `${currentDate.getFullYear()}/${currentDate.getMonth() + 1}`;

  return (
    <Navigate to={defaultCalendar} replace />
  );
}

export default RedirectToCalendar;

import { useParams } from 'react-router-dom';

export default function useCastedParams(paramsList) {
  const params = useParams();

  Object.entries(paramsList).forEach((t) => {
    const [paramName, castFunc] = t;
    params[paramName] = castFunc(params[paramName]);
  });

  return params;
}

export const asInt = (x) => parseInt(x, 10);

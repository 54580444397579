if (process.env.NODE_ENV === 'production') {
  module.exports = {
    apiUrl: 'https://api-timetogo.mel2.lviv.ua/',
    baseUrl: 'https://timetogo.mel2.lviv.ua/',
    debug: () => {
      /* in production do nothing */
    },
  };
} else {
  module.exports = {
    apiUrl: 'http://localhost:8080/',
    baseUrl: 'http://localhost:3000/',
    // eslint-disable-next-line no-console
    debug: console.log,
  };
}

export default function FrequencyText(frequency, date) {
  if (frequency === 'NEVER') {
    return 'NEVER';
  }

  if (frequency === 'EVERY_DAY') {
    return 'every day';
  }

  if (frequency === 'EVERY_WEEK') {
    return `every ${date.toLocaleString('en', {
      weekday: 'long',
    })}`;
  }

  if (frequency === 'EVERY_2_WEEK') {
    return `every 2nd ${date.toLocaleString('en', {
      weekday: 'long',
    })}`;
  }

  if (frequency === 'EVERY_MONTH') {
    const num = date.toLocaleString('en', {
      day: 'numeric',
    });
    return `every ${num}st day of month`;
  }

  if (frequency === 'EVERY_YEAR') {
    const num = date.toLocaleString('en', {
      day: 'numeric',
    });
    const month = date.toLocaleString('en', {
      month: 'long',
    });
    return `every ${num} of ${month}`;
  }
  return 'ERROR';
}

import React from 'react';
import UserShape from '../propTypes/UserShape';

export default function CalendarMember({ member }) {
  return (
    <li className="list-group-item bg-transparent d-flex justify-content-between">
      {member.user.firstName}
      &nbsp;
      {member.user.lastName}

      <span className="badge bg-secondary text-end">{member.role}</span>

    </li>
  );
}

CalendarMember.propTypes = { member: UserShape.isRequired };

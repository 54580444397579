import { Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import roles from '../roles';
import FormRow from './FormRow';

export default function NewLinkModal({
  opened, close, createLink, error,
}) {
  const [role, setRole] = useState(roles[1][0]);

  return (
    <Modal show={opened} onHide={() => close()}>
      <Modal.Header closeButton>
        <Modal.Title>Create invitation link</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          id="createCalendarForm"
          onSubmit={(e) => {
            e.preventDefault();
            createLink({
              role,
            });
            close();
          }}
        >
          {error
            && (
              <div className="alert alert-danger login-error">
                {error}
              </div>
            )}

          <FormRow label="Role" inputId="role">
            <select className="form-select" onChange={(e) => setRole(e.target.value)} value={role}>
              {roles.filter(([value]) => (value !== 'OWNER'))
                .map(
                  ([value, text]) => (<option value={value} key={value}>{text}</option>),
                )}
            </select>
          </FormRow>

          <div className="d-flex justify-content-center">
            <input
              type="submit"
              className="btn btn-primary"
              value="Submit"
            />
          </div>

        </form>
      </Modal.Body>
    </Modal>
  );
}

NewLinkModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  createLink: PropTypes.func.isRequired,
  error: PropTypes.node,
};

NewLinkModal.defaultProps = {
  error: null,
};

import { Link } from 'react-router-dom';
import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import TaskShape from '../propTypes/TaskShape';
import UserShape from '../propTypes/UserShape';
import CalendarMember from './CalendarMember';
import UserContext from '../UserContext';

export default function CalendarCard({ calendar }) {
  const { userId } = useContext(UserContext);

  const userRole = calendar.calendarMembers.find((m) => m.user.id === userId)?.role;
  // FIXME userRole !!!
  return (
    <li className="list-group-item mb-3">
      <div className="card card-task mb-3">
        <div className="card-body">
          <div className="card-header bg-transparent">
            <div className="position-relative">

              <h4 className="card-title task-title">
                <Link to={`/calendar/${calendar.id}`}>{calendar.name}</Link>
              </h4>
              <div className="position-absolute top-50 start-100 translate-middle">
                {
                  (userRole === 'OWNER' || userRole === 'ADMIN') && (
                    <Link
                      className="btn btn-secondary"
                      to={`/calendar/${calendar.id}/edit`}
                    >
                      <i className="bi bi-gear-fill" />
                    </Link>
                  )
                }

              </div>
            </div>

          </div>

          <div>
            <h5 className="mt-3">Members:</h5>
            <ul className="list-group">
              {calendar.calendarMembers.map((member) => (
                <CalendarMember member={member} key={member.user.id} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </li>
  );
}

CalendarCard.propTypes = {
  calendar: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    tasks: PropTypes.arrayOf(TaskShape),
    calendarMembers: PropTypes.arrayOf(
      PropTypes.shape({
        role: PropTypes.string,
        user: UserShape,
      }),
    ),
  }).isRequired,
};

import './AboutUsPage.scss';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Modal } from 'react-bootstrap';
import preval from 'preval.macro';

export default function AboutUsPage() {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="back-holder about-us">
      <Helmet>
        <title>About us</title>
      </Helmet>

      <Modal show={showModal} onHide={() => setShowModal(false)} dialogClassName="bg-dark text-center" contentClassName="contacts-modal">
        <Modal.Header closeButton>
          <Modal.Title>Create task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <a
            className="btn btn-dark feedback-btn"
            href="mailto:kvashena.kapusta.2020@gmail.com?subject=Feedback&body=Message"
          >
            Send Feedback
          </a>

          <h5
            className="modal-title mt-3"
            id="modalLabel"
          >
            Our Team:
          </h5>
          <hr />
          <div>
            <p>Danylo Melnyk</p>
            <p>Anastasiia Rokytska</p>
            <p>Andriy Pasemko</p>
            <p>Victoriia Halik</p>
            <p>Maksym Syrvatka</p>
            <p>Khrystyna Popiv</p>
          </div>
          <hr />

          <h5
            className="modal-title mt-3"
            id="modalLabel"
          >
            Rewrote to Java Spring and React from Django by:
          </h5>
          <hr />
          <div>
            <p>Danylo Melnyk</p>
          </div>
          <hr />
        </Modal.Body>
      </Modal>

      <footer className="py-3 fixed-bottom">
        <div className="container text-center">
          <button
            type="button"
            className="btn contact-btn"
            onClick={() => setShowModal(true)}
          >
            Contact us
          </button>
        </div>

        <div className="text-center mt-2">
          Build Date:&nbsp;
          {preval`module.exports = new Date().toLocaleString();`}
        </div>
      </footer>
    </div>
  );
}

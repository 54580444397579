import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import GoToDay from './GoToDay';

export default function CalendarHead({
  calendarId,
  year,
  month,
  openModal,
  editingPermission,
}) {
  let previous = `/calendar/${calendarId}/`;
  let next = `/calendar/${calendarId}/`;

  if (month === 1) {
    previous += `${year - 1}/12/`;
  } else {
    previous += `${year}/${month - 1}/`;
  }

  if (month === 12) {
    next += `${year + 1}/1/`;
  } else {
    next += `${year}/${month + 1}/`;
  }

  return (
    <div className="row mt-2 mb-1">
      <div className="col-12 col-xl-9 mb-2 mb-xl-auto">
        <div className="row px-0 px-xl-5">
          <div className="col-3">
            <Link className="btn calendar-btn" to={previous}>
              {'<< Previous Month'}
            </Link>
          </div>

          <div className="col-3">
            <Button
              type="button"
              variant="info"
              className="btn btn-info calendar-btn btn-add-task"
              disabled={!editingPermission}
              onClick={() => openModal('task')}
            >
              + New Task
            </Button>
          </div>

          <div className="col-3">
            <button
              type="button"
              className="btn btn-info calendar-btn btn-add-event"
              onClick={() => openModal('event')}
            >
              + New Event
            </button>
          </div>

          <div className="col-3">
            <Link className="btn btn-info calendar-btn" to={next}>
              {'Next Month >>'}
            </Link>
          </div>
        </div>
      </div>
      <div className="col">
        <GoToDay calendarId={calendarId} />
      </div>
    </div>
  );
}

CalendarHead.propTypes = {
  calendarId: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
  openModal: PropTypes.func.isRequired,
  editingPermission: PropTypes.bool.isRequired,
};

import './CalendarPage.scss';

import React, { useContext, useState } from 'react';
import api, { config } from '../api';

import CalendarHead from '../components/calendar/CalendarHead';
import Calendar from '../components/calendar/Calendar';
import CalendarSidebar from '../components/calendar/CalendarSidebar';
import UserContext from '../UserContext';
import NewTaskModal from '../components/NewTaskModal';
import useRequest from '../hooks/useRequest';
import LoadedDecorator from '../components/LoadedDecorator';
import useCastedParams, { asInt } from '../hooks/useCastedParams';
import CalendarFish from '../components/calendar/CalendarFish';

export default function CalendarPage() {
  const {
    calendarId, year, month,
  } = useCastedParams(
    {
      calendarId: asInt,
      year: asInt,
      month: asInt,
    },
  );
  const { token } = useContext(UserContext);

  const [openedModal, setOpenedModal] = useState(null);
  const [ver, setVersion] = useState(0);

  const [calendar, isLoaded, error] = useRequest(
    () => api.get(`/calendar/${calendarId}/${year}/${month}/`, config(token)),
    [calendarId, year, month, ver],
  );

  const fish = (
    <CalendarFish
      calendarId={calendarId}
      year={year}
      month={month}
    />
  );

  return (
    <div className="container-fluid">
      <NewTaskModal
        openedModal={openedModal}
        openModal={setOpenedModal}
        calendarId={calendarId}
        updateCallback={() => setVersion(ver + 1)}
      />
      {/* TODO check user roles */}
      <CalendarHead
        calendarId={calendarId}
        year={year}
        month={month}
        openModal={setOpenedModal}
        editingPermission
      />
      <div className="row pt-1">
        <div className="col-12 col-xl-9">
          <LoadedDecorator
            showContent={isLoaded && !error}
            error={error}
            fish={fish}
          >
            <Calendar
              calendar={calendar}
              isOk={isLoaded && !error}
              calendarId={calendarId}
              year={year}
              month={month}
            />
          </LoadedDecorator>
        </div>
        <div className="col">
          <CalendarSidebar />
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import PropTypes from 'prop-types';

export default function LoadedDecorator({
  error, showContent, children, fish,
}) {
  return (
    <>
      { !error && !showContent && (

        fish || (
          <div className="d-flex justify-content-center">
            <div className="spinner-border my-3" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )
      )}

      {error
        && (
          <div className="alert alert-danger login-error">
            {error}
          </div>
        )}

      {showContent && (
        children
      )}
    </>
  );
}

LoadedDecorator.propTypes = {
  error: PropTypes.string,
  showContent: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  fish: PropTypes.node,
};

LoadedDecorator.defaultProps = {
  error: null,
  fish: null,
};

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import FormRow from '../components/FormRow';

import API from '../api';
import useInput from '../hooks/useInput';
import { debug } from '../config';

function RegistrationPage() {
  const navigate = useNavigate();

  const username = useInput('');
  const email = useInput('');

  const firstName = useInput('');
  const lastName = useInput('');

  const phone = useInput('');

  const password = useInput('');
  const passwordConfirm = useInput('');

  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    if (passwordConfirm.value !== password.value) {
      setError('Passwords do not match!');
    } else {
      API.post('user', {
        email: email.value,
        firstName: firstName.value,
        lastName: lastName.value,
        password: password.value,
        passwordConfirm: passwordConfirm.value,
        phone: phone.value,
        username: username.value,
      })
        .then((res) => {
          if (res.status === 200) {
            setError('');

            navigate('/sign-in');
          } else {
            setError(`Error: ${res.data.errors}`);
          }
        })
        .catch((reason) => {
          if (reason.response.data) {
            setError(reason.response.data.message);
          } else {
            setError('Unknown error.');
            debug('Error:', reason);
          }
        });
    }
  };

  return (
    <div className="container">
      <Helmet>
        <title>Sign Up</title>
      </Helmet>

      <div className="card login-card mt-3 text-white">
        <div className="card-header login-card-header">
          <h5 className="modal-title text-center" id="modalLabel">SIGN UP</h5>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            {error
              && (
                <div className="alert alert-danger login-error">
                  {error}
                </div>
              )}

            <FormRow
              label="Username:"
              inputId="username"
              required
              autoComplete="username"
              name="username"
              maxLength="150"
              type="text"
              {...username}
            />

            <FormRow
              label="First name:"
              inputId="first-name"
              required
              name="first-name"
              maxLength="150"
              type="text"
              {...firstName}
            />

            <FormRow
              label="Last name:"
              inputId="last-name"
              name="last-name"
              maxLength="150"
              type="text"
              {...lastName}
            />

            <FormRow
              label="Email:"
              inputId="email"
              required
              autoComplete="email"
              name="email"
              maxLength="250"
              type="email"
              {...email}
            />

            <FormRow
              label="Phone:"
              inputId="phone"
              autoComplete="phone"
              name="phone"
              maxLength="12"
              type="tel"
              required
              minLength="12"
              {...phone}
            />

            <FormRow
              label="Password:"
              inputId="password"
              required
              autoComplete="new-password"
              name="password"
              type="password"
              minLength="4"
              maxLength="250"
              {...password}
            />

            <FormRow
              label="Repeat password:"
              inputId="password-repeat"
              required
              autoComplete="password-repeat"
              name="password-repeat"
              type="password"
              minLength="4"
              maxLength="250"
              {...passwordConfirm}
            />

            <div className="mb-3 mt-4 d-flex justify-content-center">
              <input type="submit" className="btn btn-login" value="Sign Up" />
            </div>
          </form>

          <p className="mt-2 text-center">
            Have an account yet?
            {' '}
            <Link className="login-link" to="/sign-in">Sign In</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default RegistrationPage;

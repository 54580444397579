import './DayPage.scss';

import React, { useContext, useState } from 'react';
import UserContext from '../UserContext';
import DayTasksList from '../components/day/DayTasksList';
import api, { config } from '../api';
import useRequest from '../hooks/useRequest';
import DayHeader from '../components/DayHeader';
import LoadedDecorator from '../components/LoadedDecorator';
import useCastedParams, { asInt } from '../hooks/useCastedParams';

export default function DayPage() {
  const { token } = useContext(UserContext);

  const {
    calendarId, year, month, day,
  } = useCastedParams(
    {
      calendarId: asInt,
      year: asInt,
      month: asInt,
      day: asInt,
    },
  );

  const [version, setVersion] = useState(0);

  const [dayData, isLoaded, error, setDayData] = useRequest(() => api.get(
    `/calendar/${calendarId}/${year}/${month}/${day}`,
    config(token),
  ), [calendarId, year, month, day, version]);

  const reload = () => {
    setVersion(version + 1);
  };

  const setTasks = (tasks) => setDayData({ tasks });

  const updateTask = (task) => {
    api.put(`/calendar/${calendarId}/${year}/${month}/${day}/tasks/${task.id}`, task, config(token))
      .then(() => {
        setTasks(dayData.tasks.map((iTask) => {
          if (iTask.id === task.id) {
            return task;
          }
          return iTask;
        }));
      });
    // FIXME check exceptions (perms)
  };

  const deleteTask = (taskId) => {
    // FIXME add confirmation modal!
    // FIXME check exceptions (perms)

    api.delete(`/calendar/${calendarId}/${year}/${month}/${day}/tasks/${taskId}`, config(token))
      .then(() => {
        setTasks(dayData.tasks.filter((iTask) => iTask.id !== taskId));
      });
  };

  return (
    <div className="container">
      <div className="card">
        <div className="card-body">
          <DayHeader
            day={day}
            month={month}
            year={year}
            calendarId={calendarId}
            updateCallback={reload}
          />
        </div>

        <LoadedDecorator showContent={!error && isLoaded} error={error}>
          <ul className="list-group list-group-flush">
            {/* {this.state.isLoaded && !this.state.error && */}
            {/* <DayEventsList events={day.events}/> */}
            {/* } */}

            <DayTasksList
              tasks={dayData?.tasks}
              updateTask={updateTask}
              calendarId={calendarId}
              deleteTask={deleteTask}
            />

            {/* {this.state.isLoaded && !this.state.error && */}
            {/* <DayActivitiesList activities={day.activities}/> */}
            {/* } */}
          </ul>
        </LoadedDecorator>
      </div>
    </div>
  );
}

import { useState } from 'react';

function useToken() {
  const getToken = () => localStorage.getItem('token');

  const [token, setToken] = useState(getToken());

  const getPayload = () => {
    if (token == null || token === 'null') {
      return null;
    }

    return JSON.parse(atob(token.split('.')[1]));
  };

  const getUserRole = () => getPayload()?.scope;

  const getUserId = () => getPayload()?.user_id;

  const isTokenValid = () => {
    const payload = getPayload();
    if (payload == null) {
      return false;
    }

    // to debug use console.log('JWT payload:', payload);

    const exp = new Date(parseInt(payload.exp, 10) * 1000);
    const now = new Date();

    // to debug use console.log('Token is ', (now < exp ?'valid' : 'invalid'), '. Expire at ', exp);
    return now < exp;
  };

  const saveToken = (userToken) => {
    localStorage.setItem('token', userToken);
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token,
    role: getUserRole(),
    isTokenValid,
    userId: getUserId(),
    logout: (navigate, location) => {
      saveToken(null);

      if (navigate && location) {
        navigate('/sign-in', { state: { from: location } });
      }
    },
  };
}

export default useToken;

import axios from 'axios';
import CONFIG from './config';

export default axios.create({
  baseURL: CONFIG.apiUrl,
});

export function config(token) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

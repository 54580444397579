import PropTypes from 'prop-types';

const UserShape = PropTypes.shape({
  id: PropTypes.number,
  username: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  role: PropTypes.string,
  phone: PropTypes.string,
  banned: PropTypes.bool,
});

export default UserShape;
